:root {
    --back_lite: #383838;
    --color_lite: #f5f6f7;
    --primary-color: #ff9822;
    --back_cmds: #4d4d4d;
    --text_secundary: #979797;
    --text-color-error: #ed6300;
    --text-color-table: #c3c3c3;

    --label-input: #6e6e6e;
    --input-back: #4d4d4d;
    --input-font-color: #f5f6f7;
    --input-border-color: #c2c2c2;
    --btn-hover-color: #ff7b00;



    --dark-lava: #544b3dff;
    --hookers-green: #4e6e58ff;
    --wintergreen-dream: #4c8577ff;
    --middle-blue-green: #a6ece0ff;
    --medium-aquamarine: #7adfbbff;

    --brunswick-green: #1b4f38ff;
    --sea-green: #278f57ff;
    --shiny-shamrock: #58a880ff;
    --raisin-black: #30272eff;
    --cultured: #f2f3f5ff;
}