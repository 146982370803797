.BarTop {
    background: #f8f9fa;
    padding: 7px;
    border: 1px solid #cdcdcd;
    margin-bottom: 6px;
    border-radius: 5px;
}

.ContainerItem {
    padding: 7px;
    border: 1px solid #cdcdcd;
}

.infoCards {
    font-size: 13px;
}

.cardBox {
    padding: 7px;
    cursor: pointer;
}

.titleCard {
    margin: 0 auto;
    font-size: 12px;
    margin-bottom: 5px;
    color: #4e4e4e;
}

.cardContent {
    padding: 2px;
}

.boxCard {
    height: 72px;
    display: flex;
    width: 120px;
    align-items: center;
    justify-content: center;
}