.svg-icon {
  width: 30px;
  height: 30px;
}

.svg-icon-selected {
  fill: white;
}

.svg-icon-noselected {
  fill: white;
}

.menuSelected {
  background: #818181;
  /* color: white;*/
  /* font-weight: 700;*/
}

.cursor-pointer {
  cursor: pointer;
}