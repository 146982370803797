ul.react-paginate {
    display: flex;
    flex-direction: row;
    justify-content: end;
    list-style-type: none;
    padding: 0 2rem;
}

ul.react-paginate li a {
    border-radius: 5px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
    margin-left: 2px;
}

ul.react-paginate li.previous a,
ul.react-paginate li.next a,
ul.react-paginate li.break a {
    border-color: transparent;
}

ul.react-paginate li.selected a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
}

ul.react-paginate li.disabled a {
    color: grey;
}

ul.react-paginate li.disable,
ul.react-paginate li.disabled a {
    cursor: default;
}

.paginate-active {
    /* background-color: #d68203; */
    font-weight: 700;
    color: orangered;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 10px;
}

.break-me {
    font-weight: 700;
    color: orangered;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 10px;
}