.PainelBase {
    position: absolute !important;
    top: 100px;
    right: 0px;
    /*width: 50vw;*/
    /* height: 88vh; */
    display: none;
    /*border-left: 2px solid #9d9d9d;*/
    z-index: 99999;
}

.PainelBaseAlerts {
    position: absolute !important;
    top: 50px;
    right: 0px;
    display: none;
    z-index: 99999;
}


.Opened {
    display: block !important;
}