@import './themes/theme.css';


.dialog-back-black {
    background-color: rgb(34 34 34 / 43%);
    color: var(--color_lite);
    height: 98vh;
    width: 350px;
}

.dialog-back {
    background-color: rgb(0 0 0 / 43%);
    color: #f7f7f7;
    height: 100vh;
    width: 350px;
}

.form-container {
    max-width: 280px;
    display: flex;
    flex-direction: column;
    /* width: 57vw; */
    justify-self: center;
    margin: 0 auto;
    min-width: 250px;
}

.btn-cmd {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    color: var(--color_lite);
}


.btn-text {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 8px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

}

.btn-text:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: rgba(25, 118, 210, 0.04);
}

.btn-text:active {
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.margin-center {
    margin-top: 5vh;
}

.info-email {
    display: flex;
    font-size: 14px;
    padding: 5px;
    text-align: center;
    margin-bottom: 15px;
    background: white;
    border-radius: 7px;
}

.backlogin {
    background: url('../assets//backgrounds/imgback01.jpg');
    background-size: cover;
    width: 100%;
    height: 98vh;
    z-index: 99999999;
}

.logo-box {
    /*height: 60px;*/
    /* position: absolute; */
    /* top: 30px; */
    /* right: 30px; */
    display: flex;
    /* align-items: center; */
    /* justify-items: center; */
    justify-content: center;
}

.logo-box img {
    height: 100px;
}

.svgAlertClass {
    fill: rgb(223, 16, 16);
    background-color: #2cfa2c;
    color: blue;
    width: 150px;
    height: 150px;
    stroke: #111ed6;
}