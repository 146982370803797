.DatagridClass {
    height: 100%;
    /*grid-template-rows: 35px !important;*/
    /* altura da linha auto */
    /*grid-auto-rows: max-content !important;*/
    /* altura da linha auto */
}

.rdg-cell {
    content: none !important;
    border-inline-end: 1px solid var(--rdg-border-color) !important;
    border-block-end: 1px solid var(--rdg-border-color) !important;
}


.DatagridClass .rdg-row {
    border-bottom: 1px solid #e0e0e0;
    /* Adiciona uma linha de separação cinza claro */
}

.rdg-checkbox-input {}

.rdg-checkbox {
    border: 1px solid #e0e0e0 !important;
    border-radius: 5px;
}

.rdg.CheckboxIcon {
    background-color: #007bff;
    content: '✓';
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}


.pagination a {
    border-radius: 5px;
}

.pagination a.active {
    border-radius: 5px;
}

.selectCellClassname {
    display: flex;
    align-items: center;
    justify-content: center;

    >input {
        margin: 0;
    }
}