form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.Input-Style input {
    box-sizing: content-box;
    height: 0.8em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 14.5px 14px;
    border-radius: 6px;
    margin-bottom: 15px;
    color: black;
}


.Input-Style textarea {
    box-sizing: content-box;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 14.5px 14px;
    border-radius: 6px;
    margin-bottom: 15px;
    resize: none;
}

.Input-Style select {
    box-sizing: content-box;
    height: 1.2em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 12px 16px;
    border-radius: 6px;
    margin-bottom: 15px;
}



form.Input-Style label {
    font-size: small;
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
}


form button {
    display: block;
    border: 0;
    cursor: pointer;
    border-radius: 4px;
    /*width: 100%;
    padding: 16px;*/
    font-weight: bold;
    font-size: 15px;
    transition: background-color 0.2s;
}

/*
form button:hover {
    background-color: var(--btn-hover-color);
}*/


form span {
    color: var(--text-color-error);
    /*margin-top: -13px;*/
    /*margin-bottom: 10px;*/
    font-size: 14px;
    display: block;
}

form input:disabled,
form input[disabled] {
    border: 1px solid #0e0e0e;
    background-color: #383838;
    color: #666666;
}


.theme-primary {
    border: 1px solid var(--input-border-color);
    background: #dddddd;
    color: rgb(0, 0, 0);
}


.theme-light {
    border: 1px solid var(--input-border-color);
    background: white;
    color: rgb(0, 0, 0);
}


.btn-icon {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 14px !important;
}

.color-primary {
    border: 1px solid var(--btn-hover-color);
    background: white;
    color: var(--btn-hover-color);
}

.color-primary:hover {
    background: var(--btn-hover-color);
    color: white;
}


input:active {
    border-color: coral !important;
}


.oculto {
    display: none;
}

.InputDate {
    text-align: center;
    width: 80px;
    border: 1px solid #b1b1b1;
}