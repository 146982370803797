.justify-left {
    justify-content: left;
}


.margin-left {
    margin-left: 0px !important;
}


.block-grid {
    display: flex;
    padding: 10px;
    justify-content: space-evenly;
    min-width: 579px;
    max-width: 800px;
}

.subblock {
    margin-left: 10px;
    display: grid;
    width: 19em
}