.max-appbar {
    display: flex;
    flex-direction: row;
    background: #838383;
    height: 48px;
    color: white;
}

.max-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.max-toolbar-itens-right {
    display: flex;
    align-items: center;
}