.alert-style {
    position: absolute;
    bottom: 15px;
    left: 13px;
    /*display: flex;*/
    max-width: 300px;
    min-width: 300px;
    z-index: 99999;
}


/* Works on Firefox */
* {
    scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 5px;
}

*::-webkit-scrollbar-track {
    background: rgb(223, 223, 223);
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(223, 213, 208);
    border-radius: 20px;
    border: 0px solid rgb(170, 170, 170);
}